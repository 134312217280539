<template>
  <div v-if="team" :key="this.team.id" class="mb-5">
    <div class="flex mb-3">
      <p class="font-barlow font-medium text-xl text-grey-light">
        Team Management /
      </p>
      <p class="ml-1 font-barlow font-bold text-xl text-grey-dark-1">
        {{ team.name }}
      </p>
    </div>
    <div class="bg-white rounded pt-3 pl-2 pb-4 shadow mb-2">
      <p class="font-barlow font-bold text-mdl text-grey-light mb-2">
        General Information
      </p>
      <div class="pl-2">
        <p class="text-sm-2 text-grey-light mb-0.4">Team Name</p>
        <p class="font-medium text-mdlh text-grey-dark-1 mb-3">
          {{ team.name }}
        </p>
      </div>
    </div>
    <div class="bg-white rounded shadow pt-3 pl-2 mb-2 pr-6">
      <p class="mb-3 font-barlow font-bold text-mdl text-grey-light mb-2">
        Members
      </p>
      <div class="pl-2">
        <TeamTable :usersCount="team.usersCount" :id="team.id" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import TeamTable from "../../../components/TeamTable/TeamTable.vue";
export default {
  components: { TeamTable },
  name: "TeamDetails",
  computed: { ...mapState({ team: (state) => state.teams.team }) },
  async created() {
    await this.clearTeam();
  },
  async mounted() {
    await this.getTeam({ id: this.$route.params.id });
  },
  methods: {
    ...mapActions({ getTeam: "teams/getTeam", clearTeam: "teams/clearTeam" }),
  },
};
</script>

<style></style>
