<template>
  <div>
    <div class="flex flex-row w-full py-2 pl-2">
      <button v-if="hasCheckboxes" class="mr-2" @click="extendToggle">
        <Checkbox :active="source.isSelected" />
      </button>
      <div class="flex w-tableIndex justify-center mr-7 pr-3">
        <p class="font-normal text-md text-grey-dark-2">
          {{ source.index + 1 }}
        </p>
      </div>
      <div class="flex flex-grow max-w-1/2">
        <p class="text-md text-grey-dark-1 font-semibold">
          {{ fullName }}
        </p>
      </div>
      <div class="flex flex-grow max-w-1/4">
        <p class="text-md text-grey-dark-2">
          {{ source.jobTitle }}
        </p>
      </div>
      <div class="flex flex-grow max-w-1/4">
        <p class="text-md text-grey-dark-2">
          {{ formatDate(source.addedOnTeam) || 'Jan 29, 2021' }}
        </p>
      </div>
    </div>
    <div class="border border-grey-light-1 border-top-none" />
  </div>
</template>
<script>
import moment from 'moment';
export default {
  name: 'ListItem',
  props: {
    source: { type: Object, default: () => ({}) },
    toggle: { type: Function, default: () => {} },
    hasCheckboxes: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    fullName() {
      return this.source.firstName + ' ' + this.source.lastName;
    },
  },
  methods: {
    extendToggle() {
      this.toggle(this.source.index);
    },
    formatDate(date) {
      return moment(date).format('MMM D, YYYY');
    },
  },
};
</script>
