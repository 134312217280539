<template>
  <div>
    <InputField
      :placeholder="inputPlaceholder"
      :label="inputLabel"
      :onChange="setKeyword"
      leftIcon
      class="mb-2 w-inputField"
    />
    <p class="text-sm-2 text-grey-dark-2 mb-2">
      Showing {{ mappedList.length }} team members
    </p>
    <div>
      <div
        class="flex flex-row w-full bg-grey-light-3 py-1 pl-2 rounded"
        :class="mappedList.length < 10 ? '' : 'pr-2'"
      >
        <button v-if="hasCheckboxes" class="mr-2" @click="toggleAll">
          <Checkbox :active="selectAll" />
        </button>
        <button
          class="flex flex-row items-center focus:outline-none w-tableIndex mr-7"
        >
          <p class="text-sm-2 text-grey-light mr-0.4">NO.</p>
        </button>
        <div class="flex flex-grow max-w-1/2">
          <button
            class="flex flex-row items-center focus:outline-none"
            @click="() => sort(0)"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">NAME</p>
            <img
              src="../../assets/img/delta-table/caret.svg"
              class="transition-all"
              :class="rotationMatrix[0] !== 1 ? 'transform rotate-180' : null"
            />
          </button>
        </div>
        <div class="flex flex-grow max-w-1/4">
          <button
            class="flex flex-row items-center focus:outline-none"
            @click="sort(1)"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">POSITION</p>
            <img
              src="../../assets/img/delta-table/caret.svg"
              class="transition-all"
              :class="rotationMatrix[1] !== 1 ? 'transform rotate-180' : null"
            />
          </button>
        </div>
        <div class="flex flex-grow max-w-1/4">
          <button
            class="flex flex-row items-center focus:outline-none"
            @click="sort(2)"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">ADDED ON</p>
            <img
              src="../../assets/img/delta-table/caret.svg"
              class="transition-all"
              :class="rotationMatrix[2] !== 1 ? 'transform rotate-180' : null"
            />
          </button>
        </div>
      </div>
      <div>
        <VirtualList
          style="max-height: 360px; overflow-y: auto"
          :data-key="'id'"
          :data-sources="mappedList"
          :data-component="itemComponent"
          :extra-props="{ toggle: toggleItem, hasCheckboxes: hasCheckboxes }"
          @tobottom="triggerFetch"
        />
      </div>
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import { fetchUsers } from "@/services/api/teams.js";
import ListItem from "./ListItem.vue";
import VirtualList from "vue-virtual-scroll-list";

export default {
  name: "PeopleTable",
  components: { VirtualList },
  props: {
    inputLabel: { type: String, default: "Filter by" },
    inputPlaceholder: { type: String, default: "Search by name or position" },
    hasCheckboxes: {
      type: Boolean,
      default: false,
    },
    usersCount: { type: Number },
    id: { type: Number, default: 1 },
  },
  data: () => ({
    keyword: null,
    itemComponent: ListItem,
    rotationMatrix: [1, 1, 1],
    mappedList: [],
    selectAll: false,
    pageNr: 1,
    sorting: null,
  }),

  mounted() {
    this.updateList();
  },
  watch: {
    keyword() {
      this.pageNr = 1;
      this.mappedList = [];
      this.updateList();
    },
  },
  methods: {
    sort(index) {
      this.sorting = null;
      this.pageNr = 0;
      this.mappedList = [];
      this.resetRotationMatrix(index);
      this.rotationMatrix[index] == 1
        ? this.rotationMatrix[index]++
        : this.rotationMatrix[index]--;
      switch (index) {
        case 0:
          this.sorting = { firstName: this.rotationMatrix[index] };
          break;
        case 1:
          this.sorting = { jobtitle: this.rotationMatrix[index] };
          break;
        case 2:
          this.sorting = { createdDate: this.rotationMatrix[index] };
          break;
        default:
          break;
      }
      this.triggerFetch();
    },
    resetRotationMatrix(idx) {
      this.rotationMatrix.map((_, index, array) =>
        index == idx ? null : (array[index] = 1)
      );
    },
    setKeyword: debounce(function (value) {
      this.keyword = value;
      this.pageNr = 1;
    }, 500),
    triggerFetch() {
      if (this.pageNr < Math.ceil(this.usersCount / 10)) {
        this.pageNr++;
        this.selectAll ? this.toggleAll() : null;
        this.updateList();
      }
    },
    async updateList() {
      let newContent = await fetchUsers(
        this.id,
        this.keyword,
        this.pageNr,
        this.sorting
      ).then((rsp) => rsp.data.data);
      let map = newContent.map((item, idx) => ({
        ...item,
        isSelected: false,
        index: (this.pageNr - 1) * 10 + idx,
      }));
      this.mappedList = [...this.mappedList, ...map];
    },
    toggleItem(index) {
      this.mappedList[index].isSelected = !this.mappedList[index].isSelected;
    },
    toggleAll() {
      this.selectAll = !this.selectAll;
      this.mappedList.map((item) => (item.isSelected = this.selectAll));
    },
  },
};
</script>
